import React, { useState, useContext } from 'react';
import { Box, Typography, IconButton, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button, Checkbox, FormControlLabel } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { DataContext } from '../App';
import axios from 'axios';

function ProjectsCard({ projects }) {
  const { token, fetchProfileData } = useContext(DataContext);
  const [open, setOpen] = useState(false);
  const [newProjectName, setNewProjectName] = useState('');
  const [newProjectDescription, setNewProjectDescription] = useState('');
  const [newProjectStartDate, setNewProjectStartDate] = useState('');
  const [newProjectEndDate, setNewProjectEndDate] = useState('');
  const [endDatePresent, setEndDatePresent] = useState(false);
  const navigate = useNavigate();

  const showSeeAllButton = Array.isArray(projects) && projects.length > 3;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNewProjectName('');
    setNewProjectDescription('');
    setNewProjectStartDate('');
    setNewProjectEndDate('');
    setEndDatePresent(false);
  };

  const handleAddProject = () => {
    const newProject = {
      name: newProjectName,
      description: newProjectDescription,
      startDate: newProjectStartDate,
      endDate: endDatePresent ? 'Present' : newProjectEndDate,
    };

    axios.post('https://vouchsafe.rafman.pl/projects', newProject, {
      headers: {
        Authorization: `Basic ${token}`,
      },
    })
      .then(response => {
        console.log('Project added successfully:', response.data);
        handleClose();
        fetchProfileData();
      })
      .catch(error => {
        console.error('Error adding project:', error);
      });
  };

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat('en-GB', options).format(new Date(dateString));
  };

  return (
    <Box
      sx={{
        width: "67%",
        bgcolor: 'white',
        mt: 5,
        mb: 5,
        boxShadow: 3,
        borderRadius: 1,
        marginX: 'auto',
        height: 'auto',
        overflow: 'hidden',
        padding: 3,
        position: 'relative',
      }}
    >
      <IconButton
        sx={{
          position: 'absolute',
          top: 8,
          right: 45,
        }}
        onClick={() => navigate('projects')}
      >
        <EditIcon />
      </IconButton>
      <IconButton
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
        }}
        onClick={handleClickOpen}
      >
        <AddIcon />
      </IconButton>
      <Typography sx={{ fontWeight: 'bold', fontSize: 20, mb: 2, ml: 2 }}>
        Projects
      </Typography>
      {Array.isArray(projects) ? (
        <>
          {projects.slice(0, 3).map((project, index) => (
            <Box key={index} sx={{ mt: 4 }}>
              <Typography sx={{ fontWeight: 'bold', fontSize: 19, pl: 2 }}>
                {project.name}
              </Typography>
              <Typography sx={{ ml: 2, fontSize: 14, color: 'gray' }}>
                {formatDate(project.startDate)} - {project.endDate === 'Present' ? 'Present' : formatDate(project.endDate)}
              </Typography>
              <Typography sx={{ ml: 2, fontSize: 16, color: 'gray' }}>
                {project.description}
              </Typography>
            </Box>
          ))}
          {showSeeAllButton && (
            <Box sx={{ mt: 4, textAlign: 'center' }}>
              <Button
                component={Link}
                to="projects"
                variant="contained"
                color="primary"
              >
                See All
              </Button>
            </Box>
          )}
        </>
      ) : (
        <Typography sx={{ pl: 2 }}>No projects available.</Typography>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Project</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Project Name"
            type="text"
            fullWidth
            variant="standard"
            value={newProjectName}
            onChange={(e) => setNewProjectName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Description"
            type="text"
            fullWidth
            variant="standard"
            value={newProjectDescription}
            onChange={(e) => setNewProjectDescription(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Start Date"
            type="date"
            fullWidth
            variant="standard"
            value={newProjectStartDate}
            onChange={(e) => setNewProjectStartDate(e.target.value)}
            sx={{ mt: 2 }}
            focused
          />
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <TextField
              margin="dense"
              label="End Date"
              type="date"
              fullWidth
              variant="standard"
              value={newProjectEndDate}
              onChange={(e) => setNewProjectEndDate(e.target.value)}
              disabled={endDatePresent}
              focused
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={endDatePresent}
                  onChange={(e) => setEndDatePresent(e.target.checked)}
                />
              }
              label="Present"
              sx={{ ml: 2 }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAddProject}>Add</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ProjectsCard;
