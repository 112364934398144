import React from 'react';
import { Box, Typography, Link } from '@mui/material';

function AboutCard({ about }) {
  return (
    <Box
      sx={{
        width: "67%",
        bgcolor: 'white',
        mt: 5,
        mb: 5,
        boxShadow: 3,
        borderRadius: 1,
        marginX: 'auto',
        height: 'auto', // Adjust based on content
        overflow: 'hidden',
        padding: 3, // Added padding for better layout
      }}
    >
      <Typography 
        sx={{
          textAlign: 'start',   
          fontWeight: 'bold',
          fontSize: 20,
          ml:2

        }}
      >
        About
      </Typography>
      <Typography 
        sx={{
          textAlign: 'start',   
          width: '85%',
          fontSize: 16,
          color: 'gray',
          my: 2,  
          ml:2
        }}
      >
        {about}
      </Typography>
      <Link
        href="#"  
        sx={{
          textDecoration: 'none',
          fontSize: 16,
          ml:2,
          '&:hover': {
            textDecoration: 'underline'
          }
        }}
      >
        View Resume
      </Link>
    </Box>
  );
}

export default AboutCard;
