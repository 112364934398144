import React from 'react';
import { Typography, Box, useTheme, useMediaQuery } from '@mui/material';

function Motto({
  firstPartColor,
  secondPartColor,
  firstPartShadow,
  secondPartShadow,
  marginBottom
}) {
  const theme = useTheme();
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  // Custom font size based on screen width
  const fontSize = isXSmall ? '1rem' : isSmall ? '1.6rem' : isMedium ? '2rem' : '2.5rem';
  const marginRightVar = isXSmall ? 0.2 : isSmall ? 0.3 : isMedium ? 0.4 : 0.5;


  return (
    <Box sx={{ marginBottom: marginBottom }}>
      <Typography variant="h6" component="h2" sx={{ 
          fontSize: fontSize, // Responsive font size
          fontWeight: 'bold',
        }}>
        <span style={{ color: firstPartColor, textShadow: firstPartShadow, marginRight: `${marginRightVar}rem`}}>
          Vouch Better,
        </span>
        <span style={{ color: secondPartColor, textShadow: secondPartShadow }}>
          Vouch Safe.
        </span>
      </Typography>
    </Box>
  );
}

Motto.defaultProps = {
    firstPartColor: '#D71A21',
    secondPartColor: 'black',
    firstPartShadow: '2px 2px 4px rgba(0,0,0,0.2)',
    secondPartShadow: '2px 2px 4px rgba(0,0,0,0.2)',
    marginBottom: 2,
};

export default Motto;
