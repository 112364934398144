import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, useParams, useLocation } from 'react-router-dom';
import Navigation from './Navigation';
import ProfileCard from './ProfileCard';
import AboutCard from './AboutCard';
import SkillsCard from './SkillsCard';
import BadgesCard from './BadgesCard';
import ProjectsCard from './ProjectsCard';
import ProjectsPage from './ProjectsPage';
import SkillsPage from './SkillsPage';
import { DataContext } from '../App';
import axios from 'axios';

const ProfilePage = ({ id: propId }) => {
  const { id: paramId } = useParams();
  const location = useLocation(); // Get the current location
  const userId = paramId || propId;
  const { isLoading, setIsLoading, error, setError, token } = useContext(DataContext);
  const [profileData, setProfileData] = useState(null);
  const [skillsData, setSkillsData] = useState([]);
  const [projectsData, setProjectsData] = useState([]);
  const [availableSkills, setAvailableSkills] = useState([]);

  const fetchProfileData = () => {
    const profileUrl = userId ? `https://vouchsafe.rafman.pl/profiles/${userId}` : `https://vouchsafe.rafman.pl/profiles/me`;
    const skillsUrl = userId ? `https://vouchsafe.rafman.pl/profiles/${userId}/skills` : `https://vouchsafe.rafman.pl/profiles/me/skills`;
    const projectsUrl = userId ? `https://vouchsafe.rafman.pl/profiles/${userId}/projects` : `https://vouchsafe.rafman.pl/profiles/me/projects`;
    const allSkillsUrl = 'https://vouchsafe.rafman.pl/skills';

    setIsLoading(true);
    Promise.all([
      axios.get(profileUrl, { headers: { Authorization: `Basic ${token}` } }),
      axios.get(skillsUrl, { headers: { Authorization: `Basic ${token}` } }),
      axios.get(projectsUrl, { headers: { Authorization: `Basic ${token}` } }),
      axios.get(allSkillsUrl, { headers: { Authorization: `Basic ${token}` } })
    ]).then(([profileResponse, skillsResponse, projectsResponse, allSkillsResponse]) => {
      setProfileData(profileResponse.data);
      setSkillsData(skillsResponse.data);
      setProjectsData(projectsResponse.data);
      setAvailableSkills(allSkillsResponse.data);
      setIsLoading(false);
    }).catch(error => {
      console.error("Error fetching data:", error);
      setError('Failed to fetch data.');
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchProfileData();
  }, [token, userId, location.pathname]); // Adding location.pathname to dependencies

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!profileData) {
    return <div>No profile data available.</div>;
  }

  return (
    <div>
      <Navigation />
      <Routes>
        <Route path="/" element={
          <>
            <ProfileCard profileData={profileData} />
            <AboutCard about={profileData.about || "Insert about here"} />
            <SkillsCard skills={skillsData} token={token} />
            <BadgesCard />
            <ProjectsCard projects={projectsData} />
          </>
        } />
        <Route path="projects" element={<ProjectsPage userId={userId} />} />
        <Route path="skills" element={<SkillsPage userId={userId} />} />
      </Routes>
    </div>
  );
}

export default ProfilePage;
