import React, { useState, useContext, useEffect } from 'react';
import { Box, Typography, IconButton, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button, Card, CardContent, FormControlLabel, Switch, CircularProgress, Snackbar, Alert, Checkbox } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { DataContext } from '../App';
import axios from 'axios';

const ProjectsPage = ({ userId }) => {
  const { token } = useContext(DataContext);
  const [projects, setProjects] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [currentProject, setCurrentProject] = useState({});
  const [editedName, setEditedName] = useState('');
  const [editedDescription, setEditedDescription] = useState('');
  const [editedStartDate, setEditedStartDate] = useState('');
  const [editedEndDate, setEditedEndDate] = useState('');
  const [newProjectName, setNewProjectName] = useState('');
  const [newProjectDescription, setNewProjectDescription] = useState('');
  const [newProjectStartDate, setNewProjectStartDate] = useState('');
  const [newProjectEndDate, setNewProjectEndDate] = useState('');
  const [endDatePresent, setEndDatePresent] = useState(false);
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const getProjects = userId ? `https://vouchsafe.rafman.pl/profiles/${userId}/projects` : `https://vouchsafe.rafman.pl/profiles/me/projects`;

  const fetchProjects = () => {
    setLoading(true);
    axios.get(getProjects, { headers: { Authorization: `Basic ${token}` } })
      .then(response => {
        setProjects(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching projects:', error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchProjects();
  }, [token]);

  const navigate = useNavigate();

  const handleBack = () => {
    fetchProjects();
    navigate(-1); // Navigate back to the previous page
  };

  const handleEditOpen = (project) => {
    setCurrentProject(project);
    setEditedName(project.name);
    setEditedDescription(project.description);
    setEditedStartDate(project.startDate);
    setEditedEndDate(project.endDate === 'Present' ? '' : project.endDate);
    setEndDatePresent(project.endDate === 'Present');
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
    setCurrentProject({});
  };

  const handleDeleteOpen = (project) => {
    setCurrentProject(project);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setCurrentProject({});
  };

  const handleAddOpen = () => {
    setAddOpen(true);
  };

  const handleAddClose = () => {
    setAddOpen(false);
    setNewProjectName('');
    setNewProjectDescription('');
    setNewProjectStartDate('');
    setNewProjectEndDate('');
    setEndDatePresent(false);
  };

  const handleSave = () => {
    const updatedProject = {
      ...currentProject,
      name: editedName,
      description: editedDescription,
      startDate: editedStartDate,
      endDate: endDatePresent ? 'Present' : editedEndDate,
    };

    axios.patch(`https://vouchsafe.rafman.pl/projects/${currentProject.id}`, updatedProject, {
      headers: {
        Authorization: `Basic ${token}`
      }
    })
      .then(response => {
        console.log('Project updated successfully:', response.data);
        handleEditClose();
        setSnackbarMessage('Project updated successfully');
        setSnackbarOpen(true);
        fetchProjects();
      })
      .catch(error => {
        console.error('Error updating project:', error.response.data);
        setSnackbarMessage('Error updating project');
        setSnackbarOpen(true);
      });
  };

  const handleDelete = () => {
    axios.delete(`https://vouchsafe.rafman.pl/projects/${currentProject.id}`, {
      headers: {
        Authorization: `Basic ${token}`
      }
    })
      .then(response => {
        console.log('Project deleted successfully:', response.data);
        setSnackbarMessage('Project deleted successfully');
        setSnackbarOpen(true);
        handleDeleteClose();
        fetchProjects();
      })
      .catch(error => {
        console.error('Error deleting project:', error.response.data);
        setSnackbarMessage('Error deleting project');
        setSnackbarOpen(true);
      });
  };

  const handleAddProject = () => {
    const newProject = {
      name: newProjectName,
      description: newProjectDescription,
      startDate: newProjectStartDate,
      endDate: endDatePresent ? 'Present' : newProjectEndDate,
    };

    axios.post('https://vouchsafe.rafman.pl/projects', newProject, {
      headers: {
        Authorization: `Basic ${token}`,
      },
    })
      .then(response => {
        console.log('Project added successfully:', response.data);
        handleAddClose();
        fetchProjects(); // Fetch the latest projects
      })
      .catch(error => {
        console.error('Error adding project:', error);
      });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat('en-GB', options).format(new Date(dateString));
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <Card sx={{ width: '80%', p: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h5" sx={{ mb: 2, ml: 1, mt: 2 }}>
                All Projects
              </Typography>
            </Box>
            <IconButton onClick={handleAddOpen}>
              <AddIcon />
            </IconButton>
          </Box>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
              <CircularProgress />
            </Box>
          ) : (
            projects.map((project, index) => (
              <Card key={index} sx={{ mb: 2 }}>
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box>
                      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{project.name}</Typography>
                      <Typography variant="body2" fontStyle={'italic'}>{formatDate(project.startDate)} - {project.endDate === 'Present' ? 'Present' : formatDate(project.endDate)}</Typography>
                      <Typography variant="body2">{project.description}</Typography>
                    </Box>
                    <Box>
                      <IconButton onClick={() => handleEditOpen(project)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteOpen(project)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            ))
          )}

          <Dialog open={editOpen} onClose={handleEditClose}>
            <DialogTitle>Edit Project</DialogTitle>
            <DialogContent>
              <TextField
                margin="dense"
                label="Name"
                type="text"
                fullWidth
                variant="standard"
                value={editedName}
                onChange={(e) => setEditedName(e.target.value)}
              />
              <TextField
                margin="dense"
                label="Description"
                type="text"
                fullWidth
                variant="standard"
                value={editedDescription}
                onChange={(e) => setEditedDescription(e.target.value)}
              />
              <TextField
                margin="dense"
                label="Start Date"
                type="date"
                fullWidth
                variant="standard"
                value={editedStartDate}
                onChange={(e) => setEditedStartDate(e.target.value)}
                sx={{ mt: 2 }}
              />
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <TextField
                  margin="dense"
                  label="End Date"
                  type="date"
                  fullWidth
                  variant="standard"
                  value={editedEndDate}
                  onChange={(e) => setEditedEndDate(e.target.value)}
                  disabled={endDatePresent}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={endDatePresent}
                      onChange={(e) => setEndDatePresent(e.target.checked)}
                    />
                  }
                  label="Present"
                  sx={{ ml: 2 }}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditClose}>Cancel</Button>
              <Button onClick={handleSave}>Save</Button>
            </DialogActions>
          </Dialog>

          <Dialog open={deleteOpen} onClose={handleDeleteClose}>
            <DialogTitle>Delete Project</DialogTitle>
            <DialogContent>
              <Typography>Are you sure you want to delete this project?</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteClose}>Cancel</Button>
              <Button onClick={handleDelete} color="error">Delete</Button>
            </DialogActions>
          </Dialog>

          <Dialog open={addOpen} onClose={handleAddClose}>
            <DialogTitle>Add New Project</DialogTitle>
            <DialogContent>
              <TextField
                margin="dense"
                label="Project Name"
                type="text"
                fullWidth
                variant="standard"
                value={newProjectName}
                onChange={(e) => setNewProjectName(e.target.value)}
              />
              <TextField
                margin="dense"
                label="Description"
                type="text"
                fullWidth
                variant="standard"
                value={newProjectDescription}
                onChange={(e) => setNewProjectDescription(e.target.value)}
              />
              <TextField
                margin="dense"
                label="Start Date"
                type="date"
                fullWidth
                variant="standard"
                value={newProjectStartDate}
                onChange={(e) => setNewProjectStartDate(e.target.value)}
                sx={{ mt: 2 }}
                focused
              />
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <TextField
                  margin="dense"
                  label="End Date"
                  type="date"
                  fullWidth
                  variant="standard"
                  value={newProjectEndDate}
                  onChange={(e) => setNewProjectEndDate(e.target.value)}
                  disabled={endDatePresent}
                  focused
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={endDatePresent}
                      onChange={(e) => setEndDatePresent(e.target.checked)}
                    />
                  }
                  label="Present"
                  sx={{ ml: 2 }}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleAddClose}>Cancel</Button>
              <Button onClick={handleAddProject}>Add</Button>
            </DialogActions>
          </Dialog>

          <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
            <Alert onClose={handleSnackbarClose} severity={snackbarMessage.includes('Error') ? 'error' : 'success'} sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Card>
      </Box>
    </>
  );
}

export default ProjectsPage;
