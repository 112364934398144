import React, { useState } from 'react';
import { AppBar, Toolbar, Box, IconButton, Menu, MenuItem, Button, useMediaQuery, useTheme, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { Link as RouterLink } from 'react-router-dom';
import Logo from '../assets/vouchsafe.png';
import Searchbar from './Searchbar';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function Navigation() {
  const theme = useTheme();
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const isMobile = useMediaQuery(theme.breakpoints.down('lg')); 
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm')); 
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchOpen, setSearchOpen] = useState(false);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleSearch = () => {
    setSearchOpen((prev) => !prev);
  };

  return (
    <AppBar position="static" sx={{ background: 'white', color: 'black' }}>
      <Toolbar sx={{ justifyContent: 'space-between', display: 'flex' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={Logo} alt="Vouchsafe" style={{ height: 40 }} />
        </Box>

        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
          {!isHomePage && (
            isExtraSmall ? (
              <IconButton color="inherit" onClick={toggleSearch}>
                <SearchIcon />
              </IconButton>
            ) : (
              <Searchbar width={75} />

            )
          )}

          {isMobile ? (
            <>
              <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
              <IconButton color="inherit" aria-label="menu" onClick={handleMenu}>
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose} component={RouterLink} to="/">Home</MenuItem>
                <MenuItem onClick={handleClose} component={RouterLink} to="/myprofile">Profile</MenuItem>
                <MenuItem onClick={handleClose} component={RouterLink} to="/logout">Logout</MenuItem>
              </Menu>
            </>
          ) : (
            <Box sx={{ display: 'flex' }}>
              <Button color="inherit" component={RouterLink} to="/">Home</Button>
              <Button color="inherit" component={RouterLink} to="/myprofile">Profile</Button>
              <Button color="inherit" component={RouterLink} to="/Logout">Logout</Button>
            </Box>
          )}
        </Box>
      </Toolbar>
      {isExtraSmall && searchOpen && !isHomePage && (
        <Box sx={{ display: 'flex', justifyContent: 'center', padding: '0 20px', marginTop: '10px', marginBottom: '20px' }}>
          <Searchbar width={90} />
        </Box>
      )}
    </AppBar>
  );
}

export default Navigation;
