import React, { useState, createContext } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import ProfilePage from './components/ProfilePage';
import HomePage from './components/HomePage';
import SearchResultsPage from './components/SearchResultsPage';


const createAuthToken = (username, password) => {
  return btoa(`${username}:${password}`);
};

const token = createAuthToken('omareis', 'Hello12345!');
export const DataContext = createContext();

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  return (
    <DataContext.Provider value={{ isLoading, setIsLoading, error, setError, token }}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/profile/:id/*" element={<ProfilePage />} />
        <Route path="/myprofile/*" element={<ProfilePage />} />
        <Route path="/profiles/" element={<SearchResultsPage />} />
        <Route path="/profiles/search" element={<SearchResultsPage />} />

      </Routes>
    </DataContext.Provider>
  );
}

export default App;
