import React from 'react';
import { Box, Typography } from '@mui/material';
import Badge1 from '../assets/Endorsements.png';
import Badge2 from '../assets/Skills.png';
import Badge3 from '../assets/Years.png';
import Badge4 from '../assets/Years.png';

function BadgesCard() {

    const badges = [Badge1, Badge2, Badge3, Badge4];

  return (
    <Box
      sx={{
        width: "67%",
        bgcolor: 'white',

        boxShadow: 3,
        borderRadius: 1,
        marginX: 'auto',
        height: 'auto', // Adjust based on content
        overflow: 'hidden',
        padding: 3, // Added padding for better layout
      }}
    >
    <Typography 
        sx={{
          mt: 3,
          mb: 2,
          width: '100%',
          textAlign: 'start',
          fontWeight: 'bold',
          fontSize: 20,
          paddingLeft: 2,
        }}
      >
        Badges
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          width: '100%',
        }}
      >
        {badges.map((badge, index) => (
          <img 
            key={index}
            src={badge}
            alt={`Badge ${index + 1}`}
            style={{
              width: '20%'
            }}
          />
        ))}
      </Box>
      
    </Box>
  )
}

export default BadgesCard;

