import React from 'react';
import { Box, Typography } from '@mui/material';
import Logo from '../assets/logo_transparent.png';
import SearchBar from './Searchbar';
import Navigation from './Navigation';
import Motto from './Motto';

function HomePage() {
  return (
    <>
      <Navigation />

      {/* Background and overlay */}
      <Box sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        height: '90vh', // Reduced height for the background
        zIndex: -1, // Keeps the background behind the content
      }}>
        {/* Background image */}
        <Box sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '100%', // Takes up all the height of the parent
          backgroundImage: 'url("https://media.istockphoto.com/id/1368007300/video/sun-comes-out-from-behind-a-cloud-in-the-early-morning-above-the-clouds-flying-above-the.jpg?s=640x640&k=20&c=-Dvaf-lMKmMSJDxvsyfXh4rGtBKWemenyv4qn7jPLeQ=")',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }} />
        {/* Dark overlay */}
        <Box sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '100%', // Matches the height of the background image
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust black overlay opacity here
        }} />
      </Box>

      {/* Content layer */}
      <Box sx={{
        position: 'relative', // Makes sure content is positioned relative to the viewport
        zIndex: 10, // Higher zIndex ensures visibility above the background and overlay
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', // Center content horizontally
        justifyContent: 'center', // Center content vertically
        height: '50vh', // Ensures the content container matches the height of the viewport
        pt: '64px', // Add padding top to account for the navigation bar height
      }}>
       
        {/* White card under search bar */}
        <Box sx={{
          width: '67%', // Adjust width to your preference
          height: '30vh', // Height of the card
          backgroundColor: 'white', // Background color of the card
          boxShadow: '0px 4px 8px rgba(0,0,0,0.1)', // Optional shadow for better visibility
          mt: 2, // Margin top to separate from search bar
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          borderRadius: 10,
          boxShadow: 10
        }}>

        <Motto/>
        <SearchBar width={70} />
         
        </Box>
      </Box>

      {/* White section at the bottom */}
      <Box sx={{
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        height: '10vh', // White section height
        backgroundColor: 'white', // Set the background color to white
        zIndex: 1, // Ensure it stays behind the content but above the background
      }}/>
    </>
  );
}

export default HomePage;
