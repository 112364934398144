import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, FormControlLabel, Switch, Autocomplete } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function SkillsCard({ skills, token }) {
  const [open, setOpen] = useState(false);
  const [newSkill, setNewSkill] = useState('');
  const [newDescription, setNewDescription] = useState('');
  const [newDate, setNewDate] = useState('');
  const [isPinned, setIsPinned] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [skillList, setSkillList] = useState(skills);
  const [availableSkills, setAvailableSkills] = useState([]);
  const navigate = useNavigate();


  const getSkills = 'https://vouchsafe.rafman.pl/skills';

  useEffect(() => {
    axios.get(getSkills, { headers: { Authorization: `Basic ${token}` } })
      .then(response => {
        setAvailableSkills(response.data);
      })
      .catch(error => {
        console.error('Error fetching skills:', error);
      });
  }, [token]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNewSkill('');
    setNewDescription('');
    setNewDate('');
    setIsPinned(false);
    setIsVisible(true);
  };

  const handleAddSkill = () => {
    const selectedSkill = availableSkills.find(skill => skill.name === newSkill);
    if (!selectedSkill) {
      alert('Error: The skill is not available in the list.');
      return;
    }

    const skillData = {
      name: newSkill,
      description: newDescription,
      obtainedAt: newDate,
      isPinned: isPinned,
      isVisible: isVisible,
    };

    console.log('Selected Skill ID:', selectedSkill.id);
    console.log('Skill Data:', skillData);

    axios.put(`https://vouchsafe.rafman.pl/profiles/skills/${selectedSkill.id}`, skillData, {
      headers: {
        Authorization: `Basic ${token}`, // Include the token in the request headers
      }
    })
      .then(response => {
        setSkillList([...skillList, skillData]);
        handleClose();
      })
      .catch(error => {
        console.error('Error adding skill:', error);
      });
  };

  const filteredSkills = availableSkills.filter(
    skill => !skillList.some(userSkill => userSkill.name === skill.name)
  );

  const handleEditClick = () => {
    navigate('skills');
  };

  return (
    <Box
      sx={{
        width: "67%",
        bgcolor: 'white',
        mt: 5,
        mb: 5,
        boxShadow: 3,
        borderRadius: 1,
        marginX: 'auto',
        height: 'auto', // Adjust based on content
        overflow: 'hidden',
        padding: 3, // Added padding for better layout
        position: 'relative' // Added for positioning the button
      }}
    >
      <IconButton 
        sx={{ 
          position: 'absolute', 
          top: 8, 
          right: 45 
        }}
        onClick={handleEditClick}
      >
        <EditIcon />
      </IconButton>
      <IconButton 
        sx={{ 
          position: 'absolute', 
          top: 8, 
          right: 8 
        }}
        onClick={handleClickOpen}
      >
        <AddIcon />
      </IconButton>
      <Typography sx={{ fontWeight: 'bold', fontSize: 20, ml:2 }}>
        Skills
      </Typography>
      <div style={{marginLeft: '0.6rem'}}>
        {skillList.map((skill, index) => (
          <Typography key={index} sx={{
            mt: 2,
            backgroundColor: 'lightgray',
            borderRadius: 1,
            p: 1,
            fontWeight: 'bold',
            display: 'inline-block', // For layout purposes
            marginRight: 2,
            ml: 1

          }}>
            {skill.name} {/* Display the name of the skill */}
          </Typography>
      ))}
      </div>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add a new skill</DialogTitle>
        <DialogContent>
          <Autocomplete
            options={filteredSkills.map((skill) => skill.name)}
            value={newSkill}
            onChange={(event, newValue) => {
              setNewSkill(newValue);
            }}
            renderInput={(params) => <TextField {...params} label="Skill Name" variant="standard" />}
            fullWidth
          />
          <TextField
            margin="dense"
            id="description"
            label="Description"
            type="text"
            fullWidth
            variant="standard"
            value={newDescription}
            onChange={(e) => setNewDescription(e.target.value)}
          />
          <TextField
            margin="dense"
            id="date"
            type="date"
            fullWidth
            variant="standard"
            value={newDate}
            onChange={(e) => setNewDate(e.target.value)}
            sx={{ mt: 2 }}  // Added margin-top to prevent overlapping
          />
          <FormControlLabel
            control={
              <Switch
                checked={isPinned}
                onChange={(e) => setIsPinned(e.target.checked)}
              />
            }
            sx={{mt:2}}
            label="Pinned"
          />
          <FormControlLabel
            control={
              <Switch
                checked={isVisible}
                onChange={(e) => setIsVisible(e.target.checked)}
              />
            }
            sx={{mt:2}}
            label="Visible"
          />
        </DialogContent>
        <DialogActions>
          <Button sx={{color: 'gray'}} onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAddSkill}>Add</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default SkillsCard;
