import React, { useState, useContext, useEffect } from 'react';
import { Box, Typography, IconButton, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button, Card, CardContent, FormControlLabel, Switch, CircularProgress, Snackbar, Alert } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PushPinIcon from '@mui/icons-material/PushPin';
import { useNavigate } from 'react-router-dom';
import { DataContext } from '../App';
import axios from 'axios';

const SkillsPage = ({ userId }) => {
  const { token } = useContext(DataContext);
  const [availableSkills, setAvailableSkills] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [currentSkill, setCurrentSkill] = useState({});
  const [editedName, setEditedName] = useState('');
  const [editedDescription, setEditedDescription] = useState('');
  const [editedDate, setEditedDate] = useState('');
  const [newSkillName, setNewSkillName] = useState('');
  const [newSkillDescription, setNewSkillDescription] = useState('');
  const [newSkillDate, setNewSkillDate] = useState('');
  const [isPinned, setIsPinned] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const navigate = useNavigate();

  const getSkills = userId ? `https://vouchsafe.rafman.pl/profiles/${userId}/skills` : `https://vouchsafe.rafman.pl/profiles/me/skills`;

  const fetchSkills = () => {
    setLoading(true);
    axios.get(getSkills, { headers: { Authorization: `Basic ${token}` } })
      .then(response => {
        setAvailableSkills(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching skills:', error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchSkills();
  }, [token, userId]);

  const handleEditOpen = (skill) => {
    setCurrentSkill(skill);
    setEditedName(skill.name);
    setEditedDescription(skill.description);
    setEditedDate(skill.obtainedAt);
    setIsPinned(skill.isPinned);
    setIsVisible(skill.isVisible);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
    setCurrentSkill({});
  };

  const handleDeleteOpen = (skill) => {
    setCurrentSkill(skill);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setCurrentSkill({});
  };

  const handleAddOpen = () => {
    setAddOpen(true);
  };

  const handleAddClose = () => {
    setAddOpen(false);
    setNewSkillName('');
    setNewSkillDescription('');
    setNewSkillDate('');
    setIsPinned(false);
    setIsVisible(true);
  };

  const handleSave = () => {
    const updatedSkill = {
      id: currentSkill.sourceId,
      name: editedName,
      description: editedDescription,
      obtainedAt: editedDate,
      isPinned: isPinned,
      isVisible: isVisible
    };

    axios.put(`https://vouchsafe.rafman.pl/profiles/me/skills`, updatedSkill, {
      headers: {
        Authorization: `Basic ${token}`
      }
    })
      .then(response => {
        console.log('Skill updated successfully:', response.data);
        handleEditClose();
        setSnackbarMessage('Skill updated successfully');
        setSnackbarOpen(true);
        fetchSkills();
      })
      .catch(error => {
        console.error('Error updating skill:', error.response.data);
        setSnackbarMessage('Error updating skill');
        setSnackbarOpen(true);
      });
  };

  const handleDelete = () => {
    axios.delete(`https://vouchsafe.rafman.pl/profiles/me/skills/${currentSkill.sourceId}`, {
      headers: {
        Authorization: `Basic ${token}`
      }
    })
      .then(response => {
        console.log('Skill deleted successfully:', response.data);
        setSnackbarMessage('Skill deleted successfully');
        setSnackbarOpen(true);
        handleDeleteClose();
        fetchSkills();
      })
      .catch(error => {
        console.error('Error deleting skill:', error.response.data);
        setSnackbarMessage('Error deleting skill');
        setSnackbarOpen(true);
      });
  };

  const handleAddSkill = () => {
    const newSkill = {
      name: newSkillName,
      description: newSkillDescription,
      obtainedAt: newSkillDate,
      isPinned: isPinned,
      isVisible: isVisible
    };

    axios.post('https://vouchsafe.rafman.pl/profiles/skills', newSkill, {
      headers: {
        Authorization: `Basic ${token}`,
      },
    })
      .then(response => {
        console.log('Skill added successfully:', response.data);
        handleAddClose();
        fetchSkills(); // Fetch the latest skills
      })
      .catch(error => {
        console.error('Error adding skill:', error);
      });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleBackClick = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <Card sx={{ width: '80%', p: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton onClick={handleBackClick}>
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h5" sx={{ mb: 2, ml: 1, mt: 2 }}>
                All Skills
              </Typography>
            </Box>
            <IconButton onClick={handleAddOpen}>
              <AddIcon />
            </IconButton>
          </Box>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
              <CircularProgress />
            </Box>
          ) : (
            availableSkills.map((skill, index) => (
              <Card key={index} sx={{ mb: 2 }}>
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box>
                      <Typography variant="h6">
                        {skill.name}
                        {skill.isPinned && <PushPinIcon sx={{ ml: 1 }} />}
                        {!skill.isVisible && <VisibilityOffIcon sx={{ ml: 1 }} />}
                      </Typography>
                      <Typography variant="body2">{skill.description}</Typography>
                      <Typography variant="body2">Acquired: {new Date(skill.obtainedAt).toLocaleDateString()}</Typography>
                    </Box>
                    <Box>
                      <IconButton onClick={() => handleEditOpen(skill)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteOpen(skill)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            ))
          )}

          <Dialog open={editOpen} onClose={handleEditClose}>
            <DialogTitle>Edit Skill</DialogTitle>
            <DialogContent>
              <TextField
                margin="dense"
                label="Name"
                type="text"
                fullWidth
                variant="standard"
                value={editedName}
                onChange={(e) => setEditedName(e.target.value)}
              />
              <TextField
                margin="dense"
                label="Description"
                type="text"
                fullWidth
                variant="standard"
                value={editedDescription}
                onChange={(e) => setEditedDescription(e.target.value)}
              />
              <TextField
                margin="dense"
                type="date"
                fullWidth
                variant="standard"
                value={editedDate}
                onChange={(e) => setEditedDate(e.target.value)}
                sx={{ mt: 3.2 }}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={isPinned}
                    onChange={(e) => setIsPinned(e.target.checked)}
                  />
                }
                label="Pinned"
                sx={{ mt: 2 }}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={isVisible}
                    onChange={(e) => setIsVisible(e.target.checked)}
                  />
                }
                label="Visible"
                sx={{ mt: 2 }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditClose}>Cancel</Button>
              <Button onClick={handleSave}>Save</Button>
            </DialogActions>
          </Dialog>

          <Dialog open={deleteOpen} onClose={handleDeleteClose}>
            <DialogTitle>Delete Skill</DialogTitle>
            <DialogContent>
              <Typography>Are you sure you want to delete this skill?</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteClose}>Cancel</Button>
              <Button onClick={handleDelete} color="error">Delete</Button>
            </DialogActions>
          </Dialog>

          <Dialog open={addOpen} onClose={handleAddClose}>
            <DialogTitle>Add New Skill</DialogTitle>
            <DialogContent>
              <TextField
                margin="dense"
                label="Skill Name"
                type="text"
                fullWidth
                variant="standard"
                value={newSkillName}
                onChange={(e) => setNewSkillName(e.target.value)}
              />
              <TextField
                margin="dense"
                label="Description"
                type="text"
                fullWidth
                variant="standard"
                value={newSkillDescription}
                onChange={(e) => setNewSkillDescription(e.target.value)}
              />
              <TextField
                margin="dense"
                type="date"
                fullWidth
                variant="standard"
                value={newSkillDate}
                onChange={(e) => setNewSkillDate(e.target.value)}
                sx={{ mt: 3.2 }}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={isPinned}
                    onChange={(e) => setIsPinned(e.target.checked)}
                  />
                }
                label="Pinned"
                sx={{ mt: 2 }}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={isVisible}
                    onChange={(e) => setIsVisible(e.target.checked)}
                  />
                }
                label="Visible"
                sx={{ mt: 2 }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleAddClose}>Cancel</Button>
              <Button onClick={handleAddSkill}>Add</Button>
            </DialogActions>
          </Dialog>

          <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
            <Alert onClose={handleSnackbarClose} severity={snackbarMessage.includes('Error') ? 'error' : 'success'} sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Card>
      </Box>
    </>
  );
}

export default SkillsPage;
