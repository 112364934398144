import React, { useState, useEffect } from 'react';
import { AppBar, InputBase, Button, Box, Grid, Card, CardContent, Typography, CardMedia } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function SearchBar({width}) {
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const handleSearch = () => {
    const capitalizedQuery = searchQuery.trim() ? capitalizeFirstLetter(searchQuery) : "";
    capitalizedQuery? navigate(`/profiles/search?query=${encodeURIComponent(capitalizedQuery)}`) : navigate(`/profiles`);
  };
  

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <>
      <AppBar position="static" color="default" elevation={0} sx={{ borderRadius: 1, width: `${width}%` }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'grey.300',
            borderRadius: 1,
            width: '100%',
            height: '35px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', margin: '0 2px 0 8px', color: 'darkgray' }}>
            <SearchIcon />
          </Box>
          <InputBase
            sx={{ flex: 1, width: 'auto', marginLeft: '4px' }}
            placeholder="Search skills..."
            inputProps={{ 'aria-label': 'search' }}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <Button
            type="submit"
            onClick={handleSearch}
            sx={{ borderRadius: '0 3px 3px 0', height: '35px', backgroundColor: '#D71A21', color: 'white' }}
          >
            Go
          </Button>
        </Box>
      </AppBar>
      </>
  );
}

export default SearchBar;


{/* <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
{profiles.map(profile => (
  <Card key={profile.id} sx={{ mb: 1, maxWidth: 345 }}>
    <CardMedia
      component="img"
      height="140"
      image={profile.image || 'path_to_default_image.jpg'}
      alt="Profile Picture"
    />
    <CardContent>
      <Typography gutterBottom variant="h5" component="div">
        {profile.name}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {profile.location || 'No location specified'}
      </Typography>
    </CardContent>
  </Card>
))}
<Button variant="contained" color="primary" onClick={() => navigate('/profiles/')}>
  Show all
</Button>
</Box> */}
